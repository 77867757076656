type Value = string | number
type Point = [Value, Value]
const halfary = (f: string) => (n: Value) => `${f} ${n}`
const unary =
  (f: string) =>
  (x: Value, y: Value) =>
    `${f} ${x} ${y}`
const binary =
  (f: string) =>
  (...p1: Point) =>
  (...p2: Point) =>
    `${f} ${p1[0]} ${p1[1]} ${p2[0]} ${p2[1]}`
const ternary =
  (f: string) =>
  (...p1: Point) =>
  (...p2: Point) =>
  (...p3: Point) =>
    `${f} ${p1[0]} ${p1[1]} ${p2[0]} ${p2[1]} ${p3[0]} ${p3[1]}`

const arc =
  (f: string) =>
  (...p1: Point) =>
  (rot: Value, largeFlag: Value, sweepFlag: Value) =>
  (...p2: Point) =>
    `${f} ${p1[0]} ${p1[1]} ${rot} ${largeFlag} ${sweepFlag} ${p2[0]} ${p2[1]}`

export const path = (...strings: string[]) => strings.join(' ')
export const _C = ternary('C')
export const _H = halfary('H')
export const _L = unary('L')
export const _M = unary('M')
export const _Q = binary('Q')
export const _T = unary('T')
export const _V = halfary('V')
export const _c = ternary('c')
export const _h = halfary('h') // to not conflict with preact h
export const _l = unary('l')
export const _m = unary('m')
export const _q = binary('q')
export const _t = unary('t')
export const _v = halfary('v')
export const _A = arc('A')
export const _a = arc('a')
