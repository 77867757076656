import { Fragment, h, JSX } from 'preact'
import { Cocktail, RecipeEntry } from '../models/entities'
import { path, _a, _C, _c, _M, _m, _L, _l, _Q, _q, _T, _h, _v, _V } from '../util/svgbuild'

const fractions: { [key: string]: number | undefined } = {
  '½': 1 / 2,
  '⅓': 1 / 3,
  '⅕': 1 / 5,
  '⅙': 1 / 6,
  '⅛': 1 / 8,
  '⅔': 2 / 3,
  '⅖': 2 / 5,
  '⅚': 5 / 6,
  '⅜': 3 / 8,
  '¾': 3 / 4,
  '⅗': 3 / 5,
  '⅝': 5 / 8,
  '⅞': 7 / 8,
  '⅘': 4 / 5,
  '¼': 1 / 4,
  '⅐': 1 / 7,
  '⅑': 1 / 9,
  '⅒': 1 / 10,
}
// gets put in a regex
const fractionsStr = Object.keys(fractions).join('')

// convert to mL
const units: { [key: string]: number | undefined } = {
  oz: 29.57,
  c: 236.6,
  tsp: 4.93,
}

type Glass = {
  vessel: string
  decor: string
  startY: number
  headroom: number
  height: number
  capacity: number
  garnishPoint: string
}
const maxHeight = 17 // collins glass, in cm
const glasses = (s: number): { [key: string]: Glass | undefined } => ({
  // 7cm dia x 15cm height
  highball: ((s: number) => ({
    vessel: path(_M(s * (4 / 15), 0), _v(s * 0.9), _h(s * (7 / 15)), _v(-s * 0.9)),
    decor: path(_M(s * (4 / 15), s * 0.9), _V(s), _h(s * (7 / 15)), _V(s * 0.9)),
    startY: s * 0.9,
    headroom: s * 0.05,
    height: s,
    capacity: 310,
    garnishPoint: `M ${(s * 11) / 15} ${0}`,
  }))((s * 15) / maxHeight),
  // 6cm dia x 17cm height
  collins: ((s: number) => ({
    vessel: `M ${s * (4.5 / 17)} 0  v ${s * 0.88} h ${s * (6 / 17)} v -${s * 0.88}`,
    decor: `M ${s * (4.5 / 17)} ${s * 0.88} V ${s} h ${s * (6 / 17)} V ${s * 0.88}`,
    startY: s * 0.88,
    headroom: s * 0.05,
    height: s,
    capacity: 340,
    garnishPoint: `M ${(s * 10.5) / 17} ${0}`,
  }))((s * 17) / maxHeight),
  // 8cm dia x 9cm height
  lowball: ((s: number) => ({
    vessel: `M ${(s * 0.5) / 9} 0 v ${s * 0.88} h ${(s * 8) / 9} v -${s * 0.88}`,
    decor: `M ${(s * 0.5) / 9} ${s * 0.88} V ${s} h ${(s * 8) / 9} V ${s * 0.88}`,
    startY: s * 0.88,
    headroom: s * 0.05,
    height: s,
    capacity: 300,
    garnishPoint: `M ${(s * 8.5) / 9} ${0}`,
  }))((s * 9) / maxHeight),
  // 11.3cm upper dia x 17cm height (2/3); 6.8cm lower dia (2/5)
  martini: ((s: number) => ({
    vessel: path(
      _M(s * (0.5 / 3), 0),
      _L(s / 2 - s * 0.05, s / 2),
      _q(s * 0.05, s * 0.03)(s * 0.1, 0),
      _L(s * (2.5 / 3), 0)
    ),
    decor: path(
      // left of stem
      _M(s / 2 - s * 0.05, s / 2),
      _c(s * 0.03, 0)(s * 0.03, s * 0.4)(0, s * 0.45),
      _L(s * (1.5 / 5), s),
      // bottom of stem
      _h(s * (2 / 5)),
      // right of stem
      _M(s / 2 + s * 0.05, s / 2),
      _c(-s * 0.03, 0)(-s * 0.03, s * 0.4)(0, s * 0.45),
      _L(s * (3.5 / 5), s)
    ),
    startY: s / 2,
    headroom: s * 0.07,
    height: s,
    capacity: 240,
    garnishPoint: `M ${(s * 2.5) / 3} 0`,
  }))((s * 17) / maxHeight),
  // 8.5cm upper dia x 11cm height (0.77); 5cm lower dia; 11.75cm width with handle
  mug: ((s: number) => ({
    vessel: path(
      _M(s, 0),
      _C(s, s * 0.1)(s * 0.8 + s * 0.15, s * (11 / 11.75))(s * 0.8, s * (11 / 11.75)),
      _L(s * 0.45, s * (11 / 11.75)),
      _C(s * 0.45 - s * 0.15, s * (11 / 11.75))(s * 0.25, s * 0.1)(s * 0.25, 0)
    ),
    decor: path(
      _M(s * 0.25, s * 0.1),
      _Q(0, s * 0.1)(0, s * 0.35),
      _T(s * 0.33, s * 0.7),
      _M(s * 0.31, s * 0.6),
      _Q(s * 0.13, s * 0.6)(s * 0.1, s * 0.35),
      _Q(s * 0.1, s * 0.2)(s * 0.25, s * 0.2)
    ),
    startY: s,
    headroom: s * 0.05,
    height: s,
    capacity: 300,
    garnishPoint: `M ${s} 0`,
  }))((s * 11.75) / maxHeight),
  // 9.5cm mug center dia x 10cm height; 12cm width with handle
  'mule mug': ((s: number) => {
    const h = 10 / 12
    const w = 9.5 / 12
    const Δrim = 1.3 / 12

    return {
      vessel: path(
        _M(s * (1 - Δrim), 0),
        _q(s * 2 * Δrim, s * (h / 2))(0, s * h),
        _h(s * -(w - 2 * Δrim)),
        _q(-s * 2 * Δrim, -s * (h / 2))(0, -s * h)
      ),
      decor: path(
        // outer handle
        _M(s * (1 - w + 0.03), s * h * 0.2),
        _l(-s * 0.07, s * 0.02),
        _l(-s * 0.07, -s * 0.05),
        _q(-s * 0.2, s * 0.25)(0, s * 0.5),
        _l(s * 0.07, -s * 0.05),
        _l(s * 0.07, s * 0.02),
        // inner handle
        _m(-s * 0.02, -s * 0.09),
        _l(-s * 0.04, s * 0.03),
        _l(-s * 0.03, -s * 0.01),
        _l(-s * 0.04, s * 0.04),
        _q(-s * 0.1, (-s * 0.38) / 2)(0, -s * 0.38),
        _l(s * 0.04, s * 0.04),
        _l(s * 0.03, -s * 0.01),
        _l(s * 0.04, s * 0.03)
      ),
      startY: s,
      headroom: s * 0.05,
      height: s,
      capacity: 550,
      garnishPoint: `M ${s * (1 - Δrim)} 0`,
    }
  })((s * 12) / maxHeight),
})

const π = Math.PI
type Garnish = { path: string; height: number }
const garnishes = (s: number): { [key: string]: Garnish | undefined } => ({
  wheel: ((s: number) => ({
    path: path(
      _m(-s / 2, 0),
      _a(s / 2, s / 2)(0, 0, 0)(s, 0),
      _m(-s, 0),
      _a(s / 2, s / 2)(0, 0, 1)(s, 0),
      _h(-s),
      _m(s / 2 - (s / 2) * Math.cos(π / 6), -(s / 2) * Math.sin(π / 6)),
      _l(s * Math.cos(π / 6), s * Math.sin(π / 6)),
      _m(
        -((s / 2) * Math.cos(π / 6) - (s / 2) * Math.cos((2 * π) / 6)),
        (s / 2) * Math.sin((2 * π) / 6) - (s / 2) * Math.sin(π / 6)
      ),
      _l(-(s * Math.cos((2 * π) / 6)), -(s * Math.sin((2 * π) / 6))),
      _m(
        (s / 2) * Math.cos((2 * π) / 6) - (s / 2) * Math.cos((3 * π) / 6),
        -((s / 2) * Math.sin((3 * π) / 6) - (s / 2) * Math.sin((2 * π) / 6))
      ),
      _v(s),
      _m(
        -((s / 2) * Math.cos((3 * π) / 6) - (s / 2) * Math.cos((4 * π) / 6)),
        -((s / 2) * Math.sin((3 * π) / 6) - (s / 2) * Math.sin((2 * π) / 6))
      ),
      _l(s * Math.cos((2 * π) / 6), -(s * Math.sin((2 * π) / 6))),
      _m(
        (s / 2) * Math.cos((1 * π) / 6) - (s / 2) * Math.cos((2 * π) / 6),
        (s / 2) * Math.sin((2 * π) / 6) - (s / 2) * Math.sin((1 * π) / 6)
      ),
      _l(-(s * Math.cos(π / 6)), s * Math.sin(π / 6))
    ),
    height: s,
  }))((s * 5) / maxHeight),
  wedge: ((s: number) => ({
    path: path(
      _m(-(s / 2), s / 6),
      _m(s / 2 - (s / 2) * Math.cos(π / 6), -((s / 2) * Math.sin(π / 6))),
      _a(s / 2, s / 2)(0, 0, 1)(s * Math.cos(π / 6), s * Math.sin(π / 6)),
      'z',
      _m(
        (s / 2) * Math.cos(π / 6) - (s / 2) * Math.cos((2 * π) / 6),
        -((s / 2) * Math.sin((2 * π) / 6) - (s / 2) * Math.sin(π / 6))
      ),
      _l((s / 2) * Math.cos((2 * π) / 6), (s / 2) * Math.sin((2 * π) / 6)),
      _v(-(s / 2)),
      _m(
        (s / 2) * Math.cos((2 * π) / 6) - (s / 2) * Math.cos((3 * π) / 6),
        (s / 2) * Math.sin((3 * π) / 6) - (s / 2) * Math.sin((2 * π) / 6)
      ),
      _l(-((s / 2) * Math.cos((2 * π) / 6)), (s / 2) * Math.sin((2 * π) / 6)),
      _l((s / 2) * Math.cos(π / 6), -((s / 2) * Math.sin(π / 6))),
      _m(s / 2 - (s / 2) * Math.cos(π / 6), (s / 2) * Math.sin(π / 6)),
      _h(-(s / 2))
    ),
    height: s * 0.35,
  }))((s * 5) / maxHeight),
  twist: ((s: number) => ({
    path: path(
      // front 1
      _m(-(s * 0.8), s * 0.2),
      _c(-(s / 15), -(s / 10))(s / 3 - s / 15, -s / 3 - s / 10)(s / 3, -(s / 3)),
      _l(s / 5, s / 20),
      _m(-s / 5 - s / 3, -s / 20 + s / 3),
      _l(s / 5, s / 20),
      _c(-(s / 15), -(s / 10))(s / 3 - s / 15, -s / 3 - s / 10)(s / 3, -(s / 3)),
      // back 1
      _l(s / 15, s / 5),
      _m(-s / 15 - s / 5.5, -s / 5 + s / 20),
      _l((s / 15) * 1.2, (s / 5) * 1.2),
      // front 2
      _c(s / 7, s / 12)(s / 3 - s / 7, -s / 2.5 + s / 12)(s / 3, -s / 2.5),
      _q(s / 10 + s / 20, 0)(s / 5, s / 20),
      _m(-s / 5 - s / 3, -s / 20 + s / 2.5),
      _q(s / 10 + s / 20, s / 20)(s / 5, s / 20),
      _c(s / 7, s / 12)(s / 3 - s / 7, -s / 2.5 + s / 12)(s / 3, -s / 2.5),
      // back 2
      _l(s / 15, s / 5),
      _m(-s / 15 - s / 8, -s / 5 + s / 7),
      _l(s / 15, s / 5),
      // front 3
      _c(s / 7, s / 12)(s / 4 - s / 7, -s / 2.5 + s / 12)(s / 4, -s / 2.5),
      _q(s / 10 + s / 20, 0)(s / 5, s / 20),
      _m(-s / 5 - s / 4, -s / 20 + s / 2.5),
      _q(s / 10 + s / 20, s / 20)(s / 5, s / 20),
      _c(s / 7, s / 12)(s / 4 - s / 7, -s / 2.5 + s / 12)(s / 4, -s / 2.5),
      // back 3
      _l(s / 15, s / 5),
      _m(-s / 15 - s / 10, -s / 5 + s / 7),
      _l((s / 15) * 0.8, (s / 5) * 0.8),
      _l((-s / 15) * 0.8 + s / 15 + s / 10, (-s / 5) * 0.8 + s / 5 - s / 7)
    ),
    height: s,
  }))((s * 4) / maxHeight),
})

// taken from https://github.com/riccardoscalco/textures/blob/master/src/lines.js
// and https://github.com/riccardoscalco/textures/blob/master/src/paths.js
// because i don't want the weight of d3.js
// skipping hexagons for now so i don't have to fiddle with width/height
// prettier-ignore
const patternFills = (s: number) => [
  // π/8
  `M 0 ${3 / 4 * s} l ${s} ${-s / 2} M 0 ${s / 4} l ${s} ${-s / 2} M 0 ${s * 5 / 4} l ${s} ${-s / 2}`,
  // 3π/8
  `M ${-s / 4} ${s} l ${s / 2} ${-s} M ${s / 4} ${s} l ${s / 2} ${-s} M ${s * 3 / 4} ${s} l ${s / 2} ${-s}`,
  // 4π/8
  `M ${s / 2} 0 l 0 ${s}`,
  // 5π/8
  `M ${-s / 4} 0 l ${s / 2} ${s} M ${s / 4} 0 l ${s / 2} ${s} M ${s * 3 / 4} 0 l ${s / 2} ${s}`,
  // 7π/8
  `M 0 ${-s / 4} l ${s} ${s / 2}M 0 ${s / 4} l ${s} ${s / 2} M 0 ${s * 3 / 4} l ${s} ${s / 2}`,
  // nylon
  `M 0 ${s / 4} l ${s / 4} 0 l 0 ${-s / 4} M ${s * 3 / 4} ${s} l 0 ${-s / 4} l ${s / 4} 0 M ${s / 4} ${s / 2} l 0 ${s / 4} l ${s / 4} 0 M ${s / 2} ${s / 4} l ${s / 4} 0 l 0 ${s / 4}`,
  // waves
  `M 0 ${s / 2} c ${s / 8} ${-s / 4}   ${s * 3 / 8} ${-s / 4}   ${s / 2} 0 c ${s / 8} ${s / 4}   ${s * 3 / 8} ${s / 4}   ${s / 2} 0 M ${-s / 2} ${s / 2} c ${s / 8} ${s / 4}   ${s * 3 / 8} ${s / 4}   ${s / 2} 0 M ${s} ${s / 2} c ${s / 8} ${-s / 4}   ${s * 3 / 8} ${-s / 4}   ${s / 2} 0`,
  // woven
  `M ${s / 4} ${s / 4}l${s / 2} ${s / 2}M${s * 3 / 4} ${s / 4}l${s / 2} ${-s / 2} M${s / 4} ${s * 3 / 4}l${-s / 2} ${s / 2}M${s * 3 / 4} ${s * 5 / 4}l${s / 2} ${-s / 2} M${-s / 4} ${s / 4}l${s / 2} ${-s / 2}`,
  // crosses
  `M ${s / 4} ${s / 4}l${s / 2} ${s / 2}M${s / 4} ${s * 3 / 4}l${s / 2} ${-s / 2}`,
  // caps
  `M ${s / 4} ${s * 3 / 4}l${s / 4} ${-s / 2}l${s / 4} ${s / 2}`,
]

// from https://stackoverflow.com/a/52171480
// prettier-ignore
// eslint-disable-next-line
const TSH=(s:string):number=>{for(var i=0,h=9;i<s.length;)h=Math.imul(h^s.charCodeAt(i++),9**9);return h^h>>>9}

export const CocktailImage = ({
  cocktail,
  size,
}: {
  cocktail: Cocktail
  size?: number
}): JSX.Element => {
  const id = cocktail.name.toString().toLowerCase().replace(/ /g, '_')
  const svgsize = size || 100
  const glassKey: string =
    ['martini', 'highball', 'lowball', 'collins', 'mule mug', 'mug'].find(s =>
      cocktail.instructions.includes(s)
    ) || (Object.entries({'cocktail glass': 'martini'}).find(([k]) =>
      cocktail.instructions.includes(k)
    ) || [null, ''])[1];
  const glass: Glass = glasses(svgsize)[glassKey] || (glasses(svgsize).lowball as Glass)

  const volumes_or_fills = cocktail.recipe
    .map((each: RecipeEntry) => {
      if (each.type === 'ToFill') return 'ToFill'
      if (!each.measure) return 0

      const arr = each.measure.match(new RegExp(`([\\d\\.]*)([${fractionsStr}]*) ?(.*)`))
      const [floatPart, fracPart, unit] = (arr || ['', '', '', '']).slice(1)
      const quantity = parseFloat(floatPart || '0') + (fractions[fracPart] || 0)

      if (unit in units) {
        return quantity * (units[unit] as number)
      }

      return 0
    })
    .reverse()

  let knownTotal = 0
  let fillCount = 0
  volumes_or_fills.forEach(x => {
    if (typeof x === 'number') {
      knownTotal += x
    } else {
      fillCount++
    }
  })
  const volumes = volumes_or_fills.map(x =>
    typeof x === 'number' ? x : (glass.capacity - knownTotal) / fillCount
  )

  const total = volumes.reduce((s, x) => s + x, 0)
  const percents = volumes.map(x => x / total)
  const fold = percents.reduce((s: number[], x: number) => [...s, (s[s.length - 1] || 0) + x], [])

  const patternSize = 10
  const patterns = patternFills(patternSize)

  const liquidHeight = glass.startY - glass.headroom

  const g = (s: string, ...aliases: string[]) =>
    cocktail.recipe.some(x => [s, ...aliases].some(s => x.ingredient.name.includes(s)))
      ? garnishes(svgsize)[s] || null
      : null
  const garnish = g('wheel') || g('wedge', 'slice') || g('twist')

  const garnSpace = svgsize * 0.15
  return (
    <svg
      width={glass.height + garnSpace}
      height={glass.height + garnSpace}
      viewBox={`-1 -${garnSpace} ${glass.height + garnSpace + 2} ${glass.height + garnSpace + 2}`}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        {patterns.map((d, i) => (
          <pattern
            key={i}
            id={`${id}patt${i}`}
            patternUnits="userSpaceOnUse"
            width={patternSize}
            height={patternSize}
          >
            <path d={d} style="stroke:#ccc; stroke-width:1" />
          </pattern>
        ))}
        <mask id={`${id}mask`}>
          <rect fill="white" x="0" y="0" width="100%" height="100%" />
          <path d={glass.vessel} style="fill:black;" />
        </mask>
      </defs>
      {percents.map((x, i) => {
        if (x === 0) return null

        const y = glass.headroom + (fold[i - 1] || 0) * liquidHeight
        const height = x * liquidHeight
        const name = cocktail.recipe[cocktail.recipe.length - 1 - i].ingredient.name
        const hash = TSH(name)
        const index = ((hash % patterns.length) + patterns.length) % patterns.length
        return (
          <Fragment key={i}>
            <rect x="0" y={y} width="100%" height={height} fill={`url(#${id}patt${index})`} />
            <path d={`M 0 ${y} h ${glass.height}`} style="stroke:#111; stroke-width:0.5" />
          </Fragment>
        )
      })}
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        style="fill:#fffff8; fill-opacity:1"
        mask={`url(#${id}mask)`}
      />
      {garnish && (
        <path
          d={`${glass.garnishPoint} ${garnish.path}`}
          style="stroke:#888; stroke-width:1; fill:transparent;"
        />
      )}
      <path d={glass.vessel} style="stroke:#111; stroke-width:2; fill-opacity:0;" />
      <path d={glass.decor} style="stroke:#111; stroke-width:2; fill-opacity:0;" />
    </svg>
  )
}
