import { Block, Col, Inline, Row } from 'jsxstyle/preact'
import { Fragment, FunctionalComponent, h, toChildArray } from 'preact'
import { App } from './components/App'
import { CocktailImage } from './components/cute_image'
import { Link } from './components/Link'
import { Navbar } from './components/Navbar'
import { Cocktail, RecipeEntry } from './models/entities'
import { useCocktails, useStock } from './util/hooks'
import { Li, Ul } from './util/library'
import { render } from './util/loadPage'

const CommaSeparate: FunctionalComponent = props => {
  const children = toChildArray(props.children)

  if (children.length < 2) {
    return <>{children}</>
  }

  return (
    <>
      {children[0]}
      {children.slice(1).map((each, index) => (
        <Fragment key={index}>, {each}</Fragment>
      ))}
    </>
  )
}
const extractGarnishes = (recipe: RecipeEntry[]): [RecipeEntry[], RecipeEntry[]] => {
  const entries = [] as RecipeEntry[]
  const garnishes = [] as RecipeEntry[]
  recipe.forEach((entry: RecipeEntry) => {
    if (entry.type === 'Garnish') {
      garnishes.push(entry)
    } else {
      entries.push(entry)
    }
  })
  return [entries, garnishes]
}

const CocktailCards = ({ cocktails }: { cocktails: Array<Cocktail> }) => {
  const stock = useStock()
  return (
    <Row flexWrap="wrap" justifyContent="center">
      {cocktails.map((cocktail: Cocktail) => {
        const [entries, garnishes] = extractGarnishes(cocktail.recipe)
        return (
          <Col
            key={cocktail.name.toString()}
            mediaQueries={{
              sm: 'screen and (max-width: 800px)',
            }}
            borderRadius="3px"
            width="50%"
            smWidth="100%"
            padding="1rem"
            justifyContent="center"
          >
            <Block fontSize="large" fontWeight="bold" text-align="center">
              <Link to={cocktail} />
            </Block>
            <Row>
              <Block alignItems="center" justifyContent="center" display="flex">
                <CocktailImage cocktail={cocktail} />
              </Block>
              <Block>
                <Ul fontStyle="italic">
                  {entries.map((each: RecipeEntry) => (
                    <Li key={each.keyProp()}>
                      <Inline color={stock.has(each.ingredient.name) ? '' : 'red'}>
                        {each.measure} {each.ingredient.name}
                      </Inline>
                    </Li>
                  ))}
                  {garnishes.length ? (
                    <Li>
                      <CommaSeparate>
                        {garnishes.map((each: RecipeEntry) => (
                          <Inline
                            key={each.keyProp()}
                            color={stock.has(each.ingredient.name) ? '' : 'red'}
                          >
                            {each.ingredient.name}
                          </Inline>
                        ))}
                      </CommaSeparate>
                    </Li>
                  ) : null}
                </Ul>
              </Block>
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}

const ToggleShowAllHeader = ({ showingAll }: { showingAll: boolean }) =>
  showingAll ? (
    <div>
      Showing all cocktails. <a href="/cocktails">Only show ready?</a>
    </div>
  ) : (
    <div>
      Showing ready cocktails. <a href="/cocktails/all">Show all?</a>
    </div>
  )

const CocktailsPage = () => {
  const cocktails = useCocktails()
  const stock = useStock()

  const showAll = window.location.href.endsWith('all')

  const shownCocktails = cocktails.filter(c => {
    if (showAll) return true

    return c.recipe
      .filter(entry => entry.type !== 'Garnish')
      .every(entry => stock.has(entry.ingredient.name))
  })

  return (
    <App>
      <header>
        <Navbar />
        <h1>Cocktails</h1>
        <hr />
      </header>
      <ToggleShowAllHeader showingAll={showAll} />
      <CocktailCards cocktails={shownCocktails} />
      <a href="/create_cocktail">Add cocktail</a>
    </App>
  )
}

render(<CocktailsPage />)
