import { h, JSX } from 'preact'

export const Navbar = (): JSX.Element => (
  <nav style={{ display: 'flex' }}>
    <ul style={{ flex: 1 }}>
      <li>
        <a href="/cocktails">Cocktails</a>
      </li>
      <li>
        <a href="/ingredients">Ingredients</a>
      </li>
    </ul>
    <ul>
      <li>
        {
          // TODO redirect to current page
          window.logged_in
            ? <a href="/logout">Logout</a>
            : <a href="/login">Login</a>
        }
      </li>
    </ul>
  </nav>
)
