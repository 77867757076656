// types should be based on the structs in src/domain/entities.rs
// any methods that take a repo (e.g. Cocktail#ready, Ingredient#stocked) will be
// populated by the server as fields instead.

import { CocktailObj, InventoryObj, RecipeEntryObj } from './raw'

export class Ingredient {
  name: string
  constructor(name: string) {
    this.name = name
  }
  toString(): string {
    return this.name
  }
}

export class Inventory {
  ingredient: Ingredient
  stocked: boolean
  constructor(req: InventoryObj) {
    this.ingredient = new Ingredient(req.ingredient)
    this.stocked = req.stocked
  }
  toRaw(): InventoryObj {
    return {
      ingredient: this.ingredient.toString(),
      stocked: this.stocked,
    }
  }
}

export type IngredientType = 'Muddled' | 'Measured' | 'ToFill' | 'Garnish'
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace IngredientType {
  export function from(type: string): IngredientType {
    switch (type) {
      case 'Muddled':
      case 'Measured':
      case 'ToFill':
      case 'Garnish':
        return type
    }
    throw new Error(`Invalid ingredient type ${type}`)
  }
}

export class RecipeEntry {
  ingredient: Ingredient
  type: IngredientType
  measure: string | undefined
  constructor(req: RecipeEntryObj) {
    this.ingredient = new Ingredient(req.ingredient)
    this.type = IngredientType.from(req.type)
    this.measure = req.measure ? req.measure : undefined
  }

  keyProp(): string {
    return `${this.measure}${this.ingredient.toString()}${this.type}`
  }

  toObj(): RecipeEntryObj {
    return {
      ingredient: this.ingredient.toString(),
      type: this.type,
      measure: this.measure
    }
  }
}

export class CocktailName {
  name: string
  constructor(id: string) {
    this.name = id
  }
  toString(): string {
    return this.name
  }
}

export class Cocktail {
  name: CocktailName
  instructions: string
  recipe: Array<RecipeEntry>
  ready: boolean
  constructor(req: CocktailObj) {
    this.name = new CocktailName(req.name)
    this.instructions = req.instructions
    this.recipe = req.recipe.map(each => new RecipeEntry(each))
    this.ready = req.ready
  }
}
