import * as hooks from 'preact/hooks'
import { Cocktail, Inventory } from '../models/entities'
import { IngredientObj, InventoryObj, CocktailObj } from '../models/raw'

declare global {
  interface Window {
    cocktail: CocktailObj
    cocktails: Array<CocktailObj>
    inventory: Array<InventoryObj>
    stock: Array<IngredientObj>
    logged_in?: boolean
  }
}

class BrokenServerError extends Error { }

/* none of these hooks will ever regenerate their result until page load */
export const useCocktail = (): Cocktail => {
  if (!window.cocktail) throw new BrokenServerError('window.cocktail should not be null!')
  const cocktail = hooks.useMemo(() => new Cocktail(window.cocktail), []);
  return cocktail
}

export const useCocktails = (): Array<Cocktail> => {
  if (!window.cocktails) throw new BrokenServerError('window.cocktails should not be null!')
  const cocktails = hooks.useMemo(() => window.cocktails.map(c => new Cocktail(c)), [])
  return cocktails
}

export const useStock = (): Set<IngredientObj> => {
  if (!window.stock) throw new BrokenServerError('window.stock should not be null!')
  const stock = hooks.useMemo(() => new Set(window.stock), [])
  return stock
}

export const useInventory = (): Array<Inventory> => {
  if (!window.inventory) throw new BrokenServerError('window.inventory should not be null!')
  const inventory = hooks.useMemo(() => window.inventory.map(i => new Inventory(i)), [])
  return inventory
}

export const useEventSetState = (
  init: string
): [string, (evt: Event) => void, (v: string) => void] => {
  const [value, setRawValue] = hooks.useState(init)
  const setValue = hooks.useCallback(
    (evt: Event) => {
      if (!(evt.target instanceof HTMLInputElement || evt.target instanceof HTMLSelectElement || evt.target instanceof HTMLTextAreaElement)) {
        throw new Error('useEventSetState must only be called from input and select elements')
      }

      setRawValue(evt.target.value)
    },
    [setRawValue]
  )
  return [value, setValue, setRawValue]
}
